@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  a {
    text-decoration: underline;
  }

  a:hover {
    background-color: #0000ff;
    color: #fffcb6;
  }

  strong {
    @apply font-[700];
  }
}

@layer components {
  .darkside-root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0000ff;
    z-index: 100;
  }

  .darkside-bottom-logos {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-gap: 50px;
  }

  .heading-md-uppercase {
    font-family: 'Solina';
    font-weight: 500;
    line-height: 155%;
    letter-spacing: 3.84px;
    text-transform: uppercase;
  }
}

@layer utilities {
  .resizable-viewport {
    height: calc(100vh - var(--header-height));
    height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  }

  .rotate-x-180 {
    transform: rotateX(180deg);
  }

  .pause-animation {
    animation-play-state: paused;
  }

  .on-bottom-safari {
    transform: translate3d(0, 0, -1px);
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/*
 * Theme transition refs
 */
.root,
#root,
#docs-root {
  --light: #fffcb6;
  --dark: #272d2a;
  --hover-bg: #0000ff;
  --hover-text: #fffcb6;
  --arrow: url('/icons/arrowDark.svg');
  --layout-content: 946px;
  --header-height: 112px;
  --short-duration: 0ms;
  --long-duration: 0s;
  --delay: 0ms;
  --cross: url('/icons/cross.svg');
  --content-xs: 240px;
  --content-sm: 720px;
  --content-md: 1024px;
  --content-lg: 1280px;
  --content-xl: 1600px;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  background-color: var(--light);
}

#blank-rectangle rect {
  fill: var(--dark);
}

img::selection {
  background-color: currentColor;
}

/*
 * Fonts
 */
@font-face {
  font-family: 'PlakatGrotesk';
  src: url('/fonts/PlakatGrotesk/PlakatGrotesk.otf') format('opentype');
}

@font-face {
  font-family: "BlankVF";
  src: url(/fonts/BlankVF/BlankVF.ttf) format("truetype");
  font-stretch: 0%;
}

@font-face {
  font-family: 'Respira';
  src: url('/fonts/Respira/Respira-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Solina';
  src: url('/fonts/Solina/Solina-Light.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Solina';
  src: url('/fonts/Solina/Solina-CondensedMedium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Solina';
  src: url('/fonts/Solina/Solina-CondensedSemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Solina';
  src: url('/fonts/Solina/Solina-Heavy.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'WorkSans';
  src: url('/fonts/WorkSans/WorkSans-Regular.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'WorkSans';
  src: url('/fonts/WorkSans/WorkSans-Bold.ttf') format('truetype');
  font-weight: 600;
}

::selection {
  background-color: var(--hover-bg);
}

/*
 * Prevent transition when going from light to dark page and vice versa
 */
.no-transition-on-load,
.no-transition-on-load * {
  transition: none;
}

/*
 * Prevent nested transitions
 */
p a,
li a {
  transition: none;
}

/*
 * Override ReactPortableText style
 */
ul {
  list-style-image: var(--cross);
  list-style-position: outside;
  padding-left: 24px;
}

li ul {
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
  padding-left: 24px;
}

ul li {
  margin: 12px;
  padding-left: 20px;
}

/*
 * Hide scrollbar.
 * Tested on Chrome, Safari and Firefox.
 */
.scrollbar-hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/*
 * VideoJS customized styling
 */
.vjs-tech {
  object-fit: contain;
}

.video-js {
  height: inherit !important;
  width: inherit !important;
}

.vjs-big-play-centered .vjs-big-play-button {
  margin-left: -1em !important;
  line-height: 1.6em !important;
  background-color: #0000ff;
  color: #fffcb6;
}

/*
 * Radio buttons
 */

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  border-color: #0000ff;
}

input[type='radio']:checked + label span {
  background-color: var(--dark);
  box-shadow: 0px 0px 0px 4px var(--light) inset;
}

input[type='radio']:checked {
  color: var(--dark);
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  margin-left: 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--light);
}

input[type='search']::selection {
  color: var(--dark);
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 36px;
  width: 36px;
  border-radius: 50em;
  background: url(../public/icons/clear.svg) no-repeat 50% 50%;
  background-size: contain;
  color: #0000ff;
  opacity: 0;
  pointer-events: none;
}

input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: all;
}

mark {
  background-color: #0000ff;
  color: var(--dark);
}
